<template>
  <div class="schools">
    <div id="header" class="header"></div>
    <div class="search-header">
      <div class="container">
        <div class="search-header-title">
          2021 Best Colleges
          <span class="search-header-title__location">in America</span>
        </div>
        <div class="search-description">
          <p>
            The Best Colleges ranking is based on rigorous analysis of academic,
            admissions, financial, and student life data from the U.S.
            Department of Education along with millions of reviews from students
            and alumni. The ranking compares more than 1,000 top colleges and
            universities in the U.S. This year's rankings have reduced the
            weight of ACT/SAT scores to reflect a general de-emphasis on test
            scores in the college admissions process.
          </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-12  d-none d-md-block">
          <div style="position:relative">
            <div
              :class="onTop ? 'container search' : 'container search-scorll'"
            >
              <div class="row">
                <div class="col-md-3 col-sm-12" style="padding-left:0">
                  <el-card class="box-card filter" v-loading="loading">
                    <div slot="header" class="clearfix text-center">
                      <h3 class="text-success">
                        {{ $t("schools.index.College Search") }}
                      </h3>
                    </div>
                    <el-collapse v-model="activeName">
                      <el-collapse-item name="Schools">
                        <template slot="title">
                          <div class="icon">
                            <i class="fas fa-list"></i>
                          </div>
                          <div style="margin-left:10px">
                            <div>{{ $t("schools.index.Schools") }}</div>
                            <div>
                              <small v-if="rank !== '' && rank !== null">
                                {{ ranks[rank].rank_year }}
                                <span v-if="ranks[rank].site_name">
                                  [{{ ranks[rank].site_name }}]
                                </span>
                                {{ ranks[rank].rank_name["en"] }}
                              </small>
                              <small v-else>{{
                                $t("schools.index.All Rankings")
                              }}</small>
                            </div>
                          </div>
                        </template>
                        <div class="item-body">
                          <el-select
                            style="width:100%"
                            v-model="rank"
                            filterable
                            placeholder="Select Rank"
                            @change="changeRank"
                          >
                            <el-option
                              :label="$t('schools.index.All Rankings')"
                              value=""
                            >
                              {{ $t("schools.index.All Rankings") }}
                            </el-option>
                            <el-option
                              v-for="(item, index) in ranks"
                              :key="index"
                              :label="
                                item.site_name
                                  ? item.rank_year +
                                    ' [' +
                                    item.site_name +
                                    '] ' +
                                    item.rank_name['en']
                                  : item.rank_year + ' ' + item.rank_name['en']
                              "
                              :value="index"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </el-collapse-item>
                      <el-collapse-item name="Location">
                        <template slot="title">
                          <div class="icon">
                            <i class="fas fa-map-marker-alt"></i>
                          </div>
                          <div style="margin-left:10px">
                            <div>{{ $t("schools.index.Location") }}</div>
                            <div>
                              <small v-if="location.length > 0">
                                <span v-for="item in location" :key="item"
                                  >{{ item }}
                                </span>
                              </small>
                              <small v-else>All</small>
                            </div>
                          </div>
                        </template>
                        <div class="item-body">
                          <el-select
                            multiple
                            style="width:100%"
                            v-model="location"
                            filterable
                            placeholder="Select State"
                            @change="searchSchool"
                          >
                            <el-option
                              mu
                              v-for="(item, index) in states"
                              :key="index"
                              :label="index + ' (' + item + ')'"
                              :value="index"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </el-collapse-item>
                      <!-- <el-collapse-item>
                        <template slot="title">
                          <div class="icon">
                            <i class="fas fa-university"></i>
                          </div>
                          <div style="margin-left:10px">
                            <div>School Name</div>
                            <div>
                              <small v-if="schoolName !== '' &&  schoolName !== null">{{
                                schoolName
                              }}</small>
                              <small v-else>All</small>
                            </div>
                          </div>
                        </template>
                        <div class="item-body">
                          <el-input
                            placeholder="School Name"
                            v-model="schoolName"
                            class="input-with-select"
                            @change="searchSchool"
                            clearable
                          >
                          </el-input>
                        </div>
                      </el-collapse-item> -->
                      <el-collapse-item name="Enrollment">
                        <template slot="title">
                          <div class="icon">
                            <i class="fas fa-graduation-cap"></i>
                          </div>
                          <div style="margin-left:10px">
                            <div>{{ $t("schools.index.Enrollment") }}</div>
                            <div>
                              <small>
                                {{ toThousands(enrollment[0]) }} ~
                                {{ toThousands(enrollment[1]) }}</small
                              >
                            </div>
                          </div>
                        </template>
                        <div class="item-body">
                          <el-slider
                            v-model="enrollment"
                            :min="enrollment_range[0]"
                            :max="enrollment_range[1]"
                            :step="1000"
                            range
                            :marks="enrollment_marks"
                            @change="searchSchool"
                          >
                          </el-slider>
                        </div>
                      </el-collapse-item>
                      <el-collapse-item name="Acceptance">
                        <template slot="title">
                          <div class="icon">
                            <i class="fas fa-percent"></i>
                          </div>
                          <div style="margin-left:10px">
                            <div>{{ $t("schools.index.Acceptance Rate") }}</div>
                            <div>
                              <small> {{ rate[0] }}% ~ {{ rate[1] }}%</small>
                            </div>
                          </div>
                        </template>
                        <div class="item-body">
                          <el-slider
                            v-model="rate"
                            :min="rate_range[0]"
                            :max="rate_range[1]"
                            :step="10"
                            range
                            :marks="rate_marks"
                            @change="searchSchool"
                          >
                          </el-slider>
                        </div>
                      </el-collapse-item>
                      <el-collapse-item name="Tuition">
                        <template slot="title">
                          <div class="icon">
                            <i class="fas fa-search-dollar"></i>
                          </div>
                          <div style="margin-left:10px">
                            <div>
                              {{ $t("schools.index.Tuition and Fees") }}
                            </div>
                            <div>
                              <small>
                                ${{ toThousands(tuition[0]) }} ~ ${{
                                  toThousands(tuition[1])
                                }}</small
                              >
                            </div>
                          </div>
                        </template>
                        <div class="item-body">
                          <el-slider
                            v-model="tuition"
                            :min="tuition_range[0]"
                            :max="tuition_range[1]"
                            :step="5000"
                            range
                            :marks="tuition_marks"
                            @change="searchSchool"
                          >
                          </el-slider>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </el-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 col-sm-12" style="padding: 0">
          <div class="college-header" style="margin:0 0 10px 0">
            <div class="row" style="margin-bottom:15px">
              <div style="width: 100%">
                <el-input
                  :placeholder="$t('schools.index.School Name')"
                  v-model="schoolName"
                  class="input-with-select"
                  @change="searchSchool"
                  clearable
                  style="width: 100%"
                >
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="searchSchool"
                  ></el-button>
                </el-input>
              </div>
            </div>
            <!-- PC端 -->
            <div class="d-none d-md-block">
              <div class="row" style="justify-content:flex-end">
                <div style="margin-right:10px;font-size:14px">
                  <span><i class="fas fa-sort"></i></span>
                  <span
                    style="margin-left:5px;line-height:28px;font-weight:500"
                    >{{ $t("schools.index.SORT BY") }}</span
                  >
                </div>
                <div style="width:150px;margin-right:10px">
                  <el-select
                    v-model="order"
                    style="width:100%"
                    @change="searchSchool"
                    size="mini"
                  >
                    <el-option
                      v-for="item in orderList"
                      :key="item.order"
                      :label="$t('schools.index.' + item.name)"
                      :value="item.order"
                      v-show="!(rank === '' && item.order === 'rank')"
                    >
                      <span v-html="item.icon" style="margin-right:10px"></span>
                      <span>{{ $t("schools.index." + item.name) }}</span>
                    </el-option>
                  </el-select>
                </div>
                <div style="width:120px">
                  <el-select
                    v-if="order === 'name'"
                    v-model="sort"
                    style="width:100%"
                    @change="searchSchool"
                    size="mini"
                  >
                    <el-option value="1" label="A - Z"></el-option>
                    <el-option value="0" label="Z - A"></el-option>
                  </el-select>
                  <el-select
                    v-else-if="order === 'rank'"
                    v-model="sort"
                    style="width:100%"
                    @change="searchSchool"
                    size="mini"
                  >
                    <el-option
                      value="1"
                      :label="$t('schools.index.High to Low')"
                    ></el-option>
                    <el-option
                      value="0"
                      :label="$t('schools.index.Low to High')"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-else
                    v-model="sort"
                    style="width:100%"
                    @change="searchSchool"
                    size="mini"
                  >
                    <el-option
                      value="0"
                      :label="$t('schools.index.High to Low')"
                    ></el-option>
                    <el-option
                      value="1"
                      :label="$t('schools.index.Low to High')"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!-- 手机端 -->
            <div class="d-block d-md-none mobile">
              <div class="text-right">
                <el-button
                  @click="searchBox = true"
                  type="primary"
                  size="mini"
                  style="margin-left: 16px;"
                >
                  <i class="fas fa-university"></i>
                  {{ $t("schools.index.College Search") }}
                </el-button>
                <el-button
                  @click="sortBox = true"
                  type="primary"
                  size="mini"
                  style="margin-left: 16px;"
                >
                  <i class="fas fa-sort"></i> {{ $t("schools.index.SORT BY") }}
                </el-button>
              </div>
              <el-drawer
                :title="$t('schools.index.College Search')"
                direction="ttb"
                :visible.sync="searchBox"
                @closed="changeRank"
                size="100%"
              >
                <div class="box-card filter" style="padding-right:0">
                  <el-collapse v-model="activeName">
                    <el-collapse-item name="Schools">
                      <template slot="title">
                        <div class="icon">
                          <i class="fas fa-list"></i>
                        </div>
                        <div style="margin-left:10px">
                          <div>{{ $t("schools.index.Schools") }}</div>
                          <div>
                            <small v-if="rank !== '' && rank !== null">
                              {{
                                ranks[rank].rank_year +
                                  " " +
                                  ranks[rank].rank_name["en"]
                              }}
                            </small>
                            <small v-else>{{
                              $t("schools.index.All Rankings")
                            }}</small>
                          </div>
                        </div>
                      </template>
                      <div class="item-body">
                        <el-select
                          style="width:100%"
                          v-model="rank"
                          filterable
                          placeholder="Select Rank"
                        >
                          <el-option
                            :label="$t('schools.index.All Rankings')"
                            value=""
                          >
                            {{ $t("schools.index.All Rankings") }}
                          </el-option>
                          <el-option
                            v-for="(item, index) in ranks"
                            :key="index"
                            :label="item.rank_year + ' ' + item.rank_name['en']"
                            :value="index"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </el-collapse-item>
                    <el-collapse-item name="Location">
                      <template slot="title">
                        <div class="icon">
                          <i class="fas fa-map-marker-alt"></i>
                        </div>
                        <div style="margin-left:10px">
                          <div>{{ $t("schools.index.Location") }}</div>
                          <div>
                            <small v-if="location.length > 0">
                              <span v-for="item in location" :key="item"
                                >{{ item }}
                              </span>
                            </small>
                            <small v-else>All</small>
                          </div>
                        </div>
                      </template>
                      <div class="item-body">
                        <el-select
                          multiple
                          style="width:100%"
                          v-model="location"
                          filterable
                          placeholder="Select State"
                        >
                          <el-option
                            v-for="(item, index) in states"
                            :key="index"
                            :label="index + ' (' + item + ')'"
                            :value="index"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </el-collapse-item>
                    <el-collapse-item name="Enrollment">
                      <template slot="title">
                        <div class="icon">
                          <i class="fas fa-graduation-cap"></i>
                        </div>
                        <div style="margin-left:10px">
                          <div>{{ $t("schools.index.Enrollment") }}</div>
                          <div>
                            <small>
                              {{ toThousands(enrollment[0]) }} ~
                              {{ toThousands(enrollment[1]) }}</small
                            >
                          </div>
                        </div>
                      </template>
                      <div class="item-body">
                        <el-slider
                          v-model="enrollment"
                          :min="enrollment_range[0]"
                          :max="enrollment_range[1]"
                          :step="1000"
                          :show-tooltip="false"
                          range
                          :marks="enrollment_marks"
                        >
                        </el-slider>
                      </div>
                    </el-collapse-item>
                    <el-collapse-item name="Acceptance">
                      <template slot="title">
                        <div class="icon">
                          <i class="fas fa-percent"></i>
                        </div>
                        <div style="margin-left:10px">
                          <div>{{ $t("schools.index.Acceptance Rate") }}</div>
                          <div>
                            <small> {{ rate[0] }}% ~ {{ rate[1] }}%</small>
                          </div>
                        </div>
                      </template>
                      <div class="item-body">
                        <el-slider
                          v-model="rate"
                          :min="rate_range[0]"
                          :max="rate_range[1]"
                          :step="10"
                          range
                          :show-tooltip="false"
                          :marks="rate_marks"
                        >
                        </el-slider>
                      </div>
                    </el-collapse-item>
                    <el-collapse-item name="Tuition">
                      <template slot="title">
                        <div class="icon">
                          <i class="fas fa-search-dollar"></i>
                        </div>
                        <div style="margin-left:10px">
                          <div>{{ $t("schools.index.Tuition and Fees") }}</div>
                          <div>
                            <small>
                              ${{ toThousands(tuition[0]) }} ~ ${{
                                toThousands(tuition[1])
                              }}</small
                            >
                          </div>
                        </div>
                      </template>
                      <div class="item-body">
                        <el-slider
                          :show-tooltip="false"
                          v-model="tuition"
                          :min="tuition_range[0]"
                          :max="tuition_range[1]"
                          :step="5000"
                          range
                          :marks="tuition_marks"
                        >
                        </el-slider>
                      </div>
                    </el-collapse-item>
                    <!-- <el-collapse-item>
                      <template slot="title">
                        <div class="icon">
                          <i class="fas fa-file-alt"></i>
                        </div>
                        <div style="margin-left:10px">
                          <div>Majors</div>
                          <div>
                            <small v-if="major.length > 0">
                              <span v-for="item in major" :key="item"
                                >{{ item }}/</span
                              >
                            </small>
                            <small v-else>
                              All Majors
                            </small>
                          </div>
                        </div>
                      </template>
                      <div class="item-body">
                        <el-select
                          style="width:100%"
                          v-model="major"
                          filterable
                          multiple
                          placeholder="Select Majors"
                        >
                          <el-option
                            v-for="(item, index) in majors"
                            :key="index"
                            :label="item"
                            :value="item"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </el-collapse-item> -->
                  </el-collapse>
                </div>
              </el-drawer>
              <el-drawer
                title="SORT BY"
                :visible.sync="sortBox"
                direction="ttb"
                size="30%"
                @closed="searchSchool"
              >
                <div style="padding: 0 20px 0 20px">
                  <div>
                    <el-select
                      v-model="order"
                      style="width:100%"
                      @change="searchSchool"
                    >
                      <el-option
                        v-for="item in orderList"
                        :key="item.order"
                        :label="$t('schools.index.' + item.name)"
                        :value="item.order"
                        v-show="!(rank === '' && item.order === 'rank')"
                      >
                        <span
                          v-html="item.icon"
                          style="margin-right:10px"
                        ></span>
                        <span>{{ $t("schools.index." + item.name) }}</span>
                      </el-option>
                    </el-select>
                  </div>
                  <div style="margin: 20px 0">
                    <el-select
                      v-if="order === 'name'"
                      v-model="sort"
                      style="width:100%"
                      @change="searchSchool"
                    >
                      <el-option value="1" label="A - Z"></el-option>
                      <el-option value="0" label="Z - A"></el-option>
                    </el-select>
                    <el-select
                      v-else-if="order === 'rank'"
                      v-model="sort"
                      style="width:100%"
                      @change="searchSchool"
                    >
                      <el-option
                        value="1"
                        :label="$t('schools.index.High to Low')"
                      ></el-option>
                      <el-option
                        value="0"
                        :label="$t('schools.index.Low to High')"
                      ></el-option>
                    </el-select>
                    <el-select
                      v-else
                      v-model="sort"
                      style="width:100%"
                      @change="searchSchool"
                    >
                      <el-option
                        value="0"
                        :label="$t('schools.index.High to Low')"
                      ></el-option>
                      <el-option
                        value="1"
                        :label="$t('schools.index.Low to High')"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </el-drawer>
            </div>
          </div>
          <hr />
          <div>
            <div class="text-center" style="margin:10px 0">
              <el-pagination
                :hide-on-single-page="true"
                @current-change="setPage"
                :page-size="pageSize"
                :current-page="page"
                :total="resultsTotal"
                :pager-count="5"
                layout="prev, pager, next"
              >
              </el-pagination>
            </div>
            <!-- PC端 -->
            <div class="d-none d-md-block">
              <div class="" v-if="schools.length > 0">
                <el-card
                  class="school"
                  v-for="(school, index) in schools"
                  :key="index"
                >
                  <div class="school-detail">
                    <div class="school-img">
                      <router-link
                        :to="{ path: `/schools/${school.slug}` }"
                        style="width:100%;display:block"
                      >
                        <img
                          v-if="school.schoolyard"
                          :src="school.schoolyard"
                          alt=""
                        />
                        <div
                          v-else
                          style="line-height:200px;text-align:center;font-size:100px"
                        >
                          <span
                            :style="
                              school.color
                                ? 'color:#' + school.color
                                : 'color:#42a16a'
                            "
                            ><i class="fas fa-school"></i
                          ></span>
                        </div>
                      </router-link>
                    </div>
                    <router-link
                      :to="{ path: `/schools/${school.slug}` }"
                      class="overview"
                    >
                      <div class="subtitle" v-if="school.name !== null">
                        <div
                          class="school-name"
                          :style="
                            school.color
                              ? 'margin-top:5px;color:#' + school.color
                              : 'margin-top:5px;color: #42a16a'
                          "
                        >
                          <span v-if="lang.value === 'EN-US'">{{
                            school.name[langList[lang.value]]
                          }}</span>
                          <span v-else
                            >{{ school.name[langList[lang.value]] }}&nbsp;({{
                              school.name["en"]
                            }})</span
                          >
                        </div>
                        <small v-if="school.admission">
                          {{ school.admission.ao_city }},
                          {{ school.admission.ao_state }}</small
                        >
                        <div
                          style="margin: 20px 0"
                          v-if="school.ranks && school.ranks.length > 0"
                        >
                          <div class="rank">
                            <span class="text-warning" style="margin-right:5px"
                              ><i class="fas fa-tag"></i
                            ></span>
                            <span>
                              <span v-html="showRank(school.ranks)"></span>
                              <!-- <router-link
                                :to="{ path: `/schools/${school.slug}/ranks` }"
                                target="_blank"
                              >
                                <b
                                  :style="
                                    school.color
                                      ? 'color:#' + school.color
                                      : 'color:#42a16a'
                                  "
                                >
                                  ({{ $t("schools.More Rankings") }})</b
                                >
                              </router-link> -->
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="overview-content">
                        <div
                          v-if="school.overview"
                          style="max-height:40px;overflow:hidden"
                        >
                          {{ school.overview[langList[lang.value]] }}
                        </div>
                        <div>
                          <router-link
                            :to="{ path: `/schools/${school.slug}` }"
                            style="width:100%;display:block"
                          >
                            <b
                              :style="
                                school.color
                                  ? 'color:#' + school.color
                                  : 'color:#42a16a'
                              "
                            >
                              {{ $t("schools.index.Read More") }}</b
                            >
                          </router-link>
                        </div>
                      </div>
                    </router-link>
                    <div class="sort">
                      <div>
                        <div class="sort-title">
                          {{ $t("schools.index.Enrollment") }}
                        </div>
                        <div class="sort-value" v-if="school.admission">
                          <span
                            v-if="school.admission.applicant_overall_count"
                            >{{
                              toThousands(
                                school.admission.applicant_overall_count
                              )
                            }}</span
                          >
                          <span v-else>
                            -
                          </span>
                        </div>
                      </div>
                      <div>
                        <div class="sort-title">
                          {{ $t("schools.index.Acceptance Rate") }}
                        </div>
                        <div class="sort-value" v-if="school.admission">
                          <span v-if="school.admission.acceptance_pct_overall"
                            >{{
                              school.admission.acceptance_pct_overall
                            }}%</span
                          >
                          <span v-else>
                            -
                          </span>
                        </div>
                      </div>
                      <div>
                        <div class="sort-title">
                          {{ $t("schools.index.Tuition and Fees") }}
                        </div>
                        <div class="sort-value" v-if="school.money">
                          <span v-if="school.money.total_tuition"
                            >${{
                              toThousands(school.money.total_tuition)
                            }}</span
                          >
                          <span v-else>
                            -
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="school-action text-center">
                    <el-button
                      key="remove"
                      v-if="getRelatedCounselingSchool(school.id)"
                      type="danger"
                      @click="() => removeSchoolFromFavorite(school.id)"
                      :style="
                        school.color
                          ? 'background:#' +
                            school.color +
                            ';border-color:#' +
                            school.color +
                            ';color:white'
                          : 'color:white'
                      "
                    >
                      <i class="fas fa-star" />
                      {{ `${$t("schools.star.Delete from my school list1")}${deleteSchoolObject}${$t("schools.star.Delete from my school list3")}` }}
                    </el-button>
                    <el-button
                      key="add"
                      v-else
                      type="success"
                      @click="() => collectSchoolIsLogin(true, school.id)"
                      :style="
                        school.color
                          ? 'background:#' +
                            school.color +
                            ';border-color:#' +
                            school.color +
                            ';color:white'
                          : 'color:white'
                      "
                    >
                      <i class="far fa-star" />
                      
                    {{ `${$t("schools.star.Add to my school list1")}${addSchoolObject}${$t("schools.star.Add to my school list3")}` }}
                    </el-button>
                  </div> -->
                </el-card>
              </div>
              <div v-else style="margin:20px 20px 10px 20px">
                <el-alert
                  v-if="this.$route.query.search !== undefined"
                  :closable="false"
                  title="沒有檢索到結果。"
                  type="info"
                >
                </el-alert>
              </div>
            </div>
            <!-- 手机端 -->
            <div class="d-block d-md-none mobile">
              <el-card
                class="school"
                style="margin:10px 0"
                v-for="(school, index) in schools"
                :key="index"
              >
                <div
                  class="school-detail school-detail-sm"
                  @click="
                    $router.push({ path: `/schools/${school.slug}` })
                  "
                >
                  <div
                    class="overview"
                    style="padding: 2px 10px 0 10px;border:none"
                  >
                    <div class="subtitle" v-if="school.name !== null">
                      <div
                        class="school-name"
                        :style="
                          school.color
                            ? 'margin-top:5px;color:#' + school.color
                            : 'margin-top:5px;color: #42a16a'
                        "
                      >
                        <label lang="en" v-if="lang.value === 'EN-US'">{{
                          school.name[langList[lang.value]]
                        }}</label>
                        <label lang="en" v-else>
                          {{ school.name[langList[lang.value]] }}&nbsp;({{
                            school.name["en"]
                          }})</label
                        >
                      </div>
                      <small v-if="school.admission">
                        {{ school.admission.ao_city }},
                        {{ school.admission.ao_state }}</small
                      >

                      <div
                        class="ranks"
                        v-if="school.ranks.length > 0"
                        style="font-size: 14px;margin:10px 0 0 0"
                      >
                        <span class="text-warning" style="margin-right: 5px;"
                          ><i class="fas fa-tag"></i
                        ></span>
                        <span v-html="showRank(school.ranks)"></span>
                      </div>
                    </div>
                  </div>
                  <div class="school-img">
                    <router-link
                      :to="{ path: `/schools/${school.slug}` }"
                      style="width:100%;display:block;"
                    >
                      <img
                        v-if="school.schoolyard"
                        :src="school.schoolyard"
                        style="border-radius:10px"
                      />
                      <div
                        v-else
                        style="padding:10px 0 15px 0;text-align:center;font-size:70px;border:solid 1px rgba(0, 0, 0, 0.1);border-radius:10px"
                      >
                        <span
                          :style="
                            school.color
                              ? 'color:#' + school.color
                              : 'color:#42a16a'
                          "
                          ><i class="fas fa-school"></i
                        ></span>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div class="row sort-detail-sm" style="margin:10px 0;">
                  <div class="col-4">
                    <div class="sort-title">
                      <span style="font-weight:500">
                        {{ $t("schools.index.Enrollment") }}
                      </span>
                    </div>
                    <div class="sort-value" v-if="school.admission">
                      <span v-if="school.admission.applicant_overall_count">{{
                        toThousands(school.admission.applicant_overall_count)
                      }}</span>
                      <span v-else>
                        -
                      </span>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="sort-title">
                      <span style="font-weight:500">
                        {{ $t("schools.index.Acceptance") }}
                      </span>
                    </div>
                    <div class="sort-value" v-if="school.admission">
                      <span v-if="school.admission.acceptance_pct_overall"
                        >{{ school.admission.acceptance_pct_overall }}%</span
                      >
                      <span v-else>
                        -
                      </span>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="sort-title">
                      <span style="font-weight:500">
                        {{ $t("schools.index.Tuition/Fees") }}
                      </span>
                    </div>
                    <div class="sort-value" v-if="school.money">
                      <span v-if="school.money.total_tuition"
                        >${{ toThousands(school.money.total_tuition) }}</span
                      >
                      <span v-else>
                        -
                      </span>
                    </div>
                  </div>
                </div>
                <!-- <div class="school-action text-center" style="padding: 10px 0">
                  <el-button
                    key="remove"
                    v-if="getRelatedCounselingSchool(school.id)"
                    type="danger"
                    @click="() => removeSchoolFromFavorite(school.id)"
                    :style="
                      school.color
                        ? 'background:#' +
                          school.color +
                          ';border-color:#' +
                          school.color +
                          ';color:white'
                        : 'color:white'
                    "
                  >
                    <i class="fas fa-star" />
                    {{ `${$t("schools.star.Delete from my school list1")}${deleteSchoolObject}${$t("schools.star.Delete from my school list3")}` }}
                  </el-button>
                  <el-button
                    key="add"
                    v-else
                    type="success"
                    @click="() => collectSchoolIsLogin(true, school.id)"
                    :style="
                      school.color
                        ? 'background:#' +
                          school.color +
                          ';border-color:#' +
                          school.color +
                          ';color:white'
                        : 'color:white'
                    "
                  >
                    <i class="far fa-star" />
                    {{ `${$t("schools.star.Add to my school list1")}${addSchoolObject}${$t("schools.star.Add to my school list3")}` }}
                  </el-button>
                </div> -->
              </el-card>
            </div>
            <div class="text-center" style="margin:10px 0">
              <el-pagination
                :hide-on-single-page="true"
                @current-change="setPage"
                :page-size="pageSize"
                :current-page="page"
                :total="resultsTotal"
                :pager-count="5"
                layout="prev, pager, next"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState } from "vuex";
/*eslint-enable */
import privateApi from "@/apis/private";
import collectSchoolApi from "@/apis/collectSchool";
import profileApi from "@/apis/profile";
import schoolApi from "@/views/schools/apis/school.js";
import enrollmentApi from "@/apis/enrollment";
import states from "@/views/schools/json/states.json";

import mixin from "./mixins/index.js";
import roleMixin from "@/mixins/role";
import _ from "lodash";
import College from "@/class/college";
import JwtService from "@/common/jwt.service";

export default {
  metaInfo() {
    return {
      title: "Schools - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [mixin, roleMixin],

  props: [],
  data() {
    return {
      activeName: [
        "Schools",
        "Location",
        "Enrollment",
        "Acceptance",
        "Tuition"
      ],
      activeLoginTab: "Login",
      searchBox: false,
      sortBox: false,
      collectSchoolDialogVisible: false,
      ifCountinueCollectSchoolDialogVisible: false,
      currentSchoolId: null,
      favoriteType: "DEFAULT",
      applicationType: "RD",
      states,
      orderList: [
        {
          icon: "<i class='fas fa-tag'></i>",
          order: "rank",
          name: "Ranking"
        },
        {
          icon: "<i class='fas fa-university'></i>",
          order: "name",
          name: "School Name"
        },
        {
          icon: "<i class='fas fa-graduation-cap'></i>",
          order: "enrollment",
          name: "Enrollment"
        },
        {
          icon: "<i class='fas fa-percent'></i>",
          order: "percent",
          name: "Acceptance Rate"
        },
        {
          icon: "<i class='fas fa-search-dollar'></i>",
          order: "tuition",
          name: "Tuition and Fees"
        }
      ],
      order: "name",
      sort: "1",
      loading: true,
      onTop: true,
      schools: [],
      langList: {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      },
      resultsTotal: 0,
      page: 1,
      pageSize: 0,
      schoolName: null,
      location: [],
      enrollment_range: [0, 14000],
      tuition_range: [5000, 50000],
      rate_range: [0, 100],
      enrollment: [0, 14000],
      tuition: [5000, 50000],
      rate: [0, 100],
      major: [],
      majors: [
        "Advanced/Graduate Dentistry and Oral Sciences",
        "Accounting and Computer Science",
        "Accounting",
        "African Languages, Literatures, and Linguistics"
      ],
      rank: "",
      ranks: [
        {
          rank_year: "2021",
          rank_name: {
            tw: "US News 大學排名",
            en: "US News 大學排名",
            cn: "US News 大學排名"
          },
          rank_source_url: ""
        },
        {
          rank_year: "2021",
          rank_name: {
            tw: "US News 理工排名",
            en: "US News 理工排名",
            cn: "US News 理工排名"
          },
          rank_source_url: ""
        },
        {
          rank_year: "2021",
          rank_name: {
            tw: "US News 理工排名",
            en: "US News 理工排名",
            cn: "US News 理工排名"
          },
          rank_source_url: ""
        },
        {
          rank_year: "2021",
          rank_name: {
            tw: "US News 理工排名",
            en: "US News 理工排名",
            cn: "US News 理工排名"
          },
          rank_source_url: ""
        },
        {
          rank_year: "2021",
          rank_name: {
            tw: "US News 理工排名",
            en: "US News 理工排名",
            cn: "US News 理工排名"
          },
          rank_source_url: ""
        }
      ],
      sessionClass: {
        course_session: {}
      },
      collectedCounselingSchools: [],
      student: null,
      toggleSignUp: false,
      isAskCounselingSchoolsTypeDialogVisible: false,
      isPhone: false
    };
  },
  computed: {
    // ...mapState("user", ["token", "lang", "profile"]),
    ...mapState("user", ["lang"]),
    tuition_marks() {
      let obj = {};
      obj["" + this.tuition_range[0] + ""] =
        "$" + this.toThousands(this.tuition_range[0]);
      obj["" + this.tuition_range[1] + ""] =
        "$" + this.toThousands(this.tuition_range[1]);
      return obj;
    },
    rate_marks() {
      let obj = {};
      obj["" + this.rate_range[0] + ""] = this.rate_range[0] + "%";
      obj["" + this.rate_range[1] + ""] = this.rate_range[1] + "%";
      return obj;
    },
    enrollment_marks() {
      let obj = {};
      obj["" + this.enrollment_range[0] + ""] = this.enrollment_range[0] + "";
      obj["" + this.enrollment_range[1] + ""] = this.enrollment_range[1] + "";
      return obj;
    },
    isAddToCounselingOrThirdAppType() {
      return (
        this.$route.name === "addCounselingSchool" ||
        this.$route.name === "addCounselingThirdAppSchool"
      );
    },
    isAddToStudent() {
      return Boolean(this.$route.query.username);
    },
    isLogin() {
      return JwtService.hasToken();
    },
    studentUserId() {
      if (this.isLogin) {
        if (this.isAddToCounselingOrThirdAppType)
          return this.counselingStudent.id;
        if (this.isAddToStudent) return this.student.id;
        return this.$store.getters["user/getProfile"].id;
      } else {
        return 0;
      }
    },
    studentCounselingSchoolsType() {
      if (this.isLogin) {
        if (this.isAddToCounselingOrThirdAppType)
          return this.counselingStudent.counseling_schools_type;
        if (this.isAddToStudent) return this.student.counseling_schools_type;
        return this.$store.getters["user/student"].counseling_schools_type;
      } else {
        return 0;
      }
    },
    collectSchoolCount() {
      return this.collectedCounselingSchools.length;
    },
    hadEnrollCounseling() {
      if (this.isAddToCounselingOrThirdAppType || this.sessionClass?.id)
        return true;
      return false;
    },
    counselingId() {
      return this.$route.params.counselingId || this.sessionClass.id;
    },
    counselingStudent() {
      return this.student;
    },
    seeWhoseSchoolList() {
      if (this.student)
        return ` ${this.student.first_name}${this.$t(
          "schools.seeMySchoolList4"
        )} `;
      return this.$t("schools.seeMySchoolList2");
    },
    addSchoolObject() {
      if (this.student) return ` ${this.student.first_name} `;
      return this.$t("schools.star.Add to my school list2");
    },
    deleteSchoolObject() {
      if (this.student) return ` ${this.student.first_name} `;
      return this.$t("schools.star.Delete from my school list2");
    },
    currentSchoolApplicationTypes() {
      if (this.currentSchoolId) {
        const currentSchool = this.schools.find(
          ({ id }) => id === this.currentSchoolId
        );
        return College.getSchoolApplicationTypes(currentSchool);
      } else {
        return [];
      }
    }
  },
  async mounted() {
    const range = await schoolApi.getRange();
    this.ranks = _.orderBy(
      range.ranks,
      ["site_name", "rank_year", "rank_name.en"],
      ["asc", "desc", "asc"]
    );
    this.enrollment_range = range.range.applicant;
    this.enrollment_range[0] = this.setMin(range.range.applicant[0], 1000);
    this.enrollment_range[1] = this.setMax(range.range.applicant[1], 1000);
    this.rate_range = range.range.acceptance;
    this.rate_range[0] = this.setMin(range.range.acceptance[0], 10);
    this.rate_range[1] = this.setMax(range.range.acceptance[1], 10);
    this.tuition_range = range.range.tuition;
    this.tuition_range[0] = this.setMin(range.range.tuition[0], 5000);
    this.tuition_range[1] = this.setMax(range.range.tuition[1], 5000);
    this.enrollment = range.range.applicant;
    this.rate = range.range.acceptance;
    this.tuition = range.range.tuition;

    if (this.$route.query.rank) {
      let rank_id = this.$route.query.rank;
      this.ranks.forEach((rank, index) => {
        if (rank.id == rank_id) {
          this.rank = index;
        }
      });
      this.order = "rank";
    } else {
      // let rank_id = "";
      // this.ranks.forEach((rank, index) => {
      //   if (rank.id == rank_id) {
      //     this.rank = index;
      //   }
      // });
      this.rank = "";
      this.order = "name";
    }

    if (this.$route.query.school) this.schoolName = this.$route.query.school;
    if (this.$route.query.page) this.page = Number(this.$route.query.page);
    if (this.$route.query.applicant)
      this.enrollment = this.$route.query.applicant;
    if (this.$route.query.state) {
      if (Array.isArray(this.$route.query.state)) {
        this.location = this.$route.query.state;
      } else {
        this.location.push(this.$route.query.state);
      }
    }
    if (this.$route.query.tuition) this.tuition = this.$route.query.tuition;
    if (this.$route.query.acceptance) this.rate = this.$route.query.acceptance;
    if (this.$route.query.order) this.order = this.$route.query.order;
    if (this.$route.query.asc) this.sort = this.$route.query.asc;
    let filterApplications = [];
    if (this.$route.params.thirdAppType) {
      filterApplications = [this.$route.params.thirdAppType];
    }
    this.loading = true;
    let rank = "";
    if (this.rank !== "") {
      rank = this.ranks[this.rank].id;
    } else {
      rank = "";
    }

    const res = await schoolApi.getSchools({
      rank: rank,
      school: this.schoolName,
      page: this.page,
      applicant: this.enrollment,
      state: this.location,
      tuition: this.tuition,
      acceptance: this.rate,
      order: this.order,
      asc: this.sort,
      application_types: filterApplications
    });

    this.schools = res.schools.data;
    this.schools.forEach(school => {
      school.ranks = _.orderBy(
        school.ranks,
        ["rank", "rank_year", "rank_name.en"],
        ["asc", "desc", "asc"]
      );
    });
    this.resultsTotal = res.schools.total;
    this.pageSize = res.schools.per_page;
    this.loading = false;
    // this.searchSchool();

    // listen to scroll
    window.addEventListener("scroll", this.stick);
  },
  async created() {
    if (this.isAddToCounselingOrThirdAppType) {
      await this.fetchCustomLesson(this.$route.params.counselingId);
    }
    if (this.isAddToStudent) {
      const {
        basic_info,
        student: { counseling_schools_type }
      } = await profileApi.getUserByName(this.$route.query.username);

      this.student = { ...basic_info, counseling_schools_type };

      let pairedQueryParams = {
        has_teacher: 1,
        is_ended: 0,
        page_size: 30,
        page: 1,
        course_session_type: "counseling",
        student_user_id: basic_info.id
      };
      let {
        session_classes: sessionClasses
      } = await enrollmentApi.fetchPrivateLessons(pairedQueryParams);
      const counseling = sessionClasses.data.find(
        ({ subject_type }) => subject_type === "Counseling"
      );
      if (counseling) {
        await this.fetchCustomLesson(counseling.id);
      }
    }
    if (this.isLogin) {
      this.fetchCollectedCounselingSchools();
    }

    window.addEventListener("resize", this.setIsPhone);
  },
  methods: {
    setIsPhone() {
      this.isPhone = document.body.clientWidth <= 768;
    },
    afterLoginSuccess() {
      this.$router.go();
    },
    goToCollectSchoolsPage() {
      if (this.hadEnrollCounseling) {
        this.$router.push({
          path: `/private/counseling/${this.counselingId}/colleges`
        });
      } else {
        this.$router.push({
          path: `/private/counseling/student/${this.$store.getters["user/getProfile"].username}/colleges`
        });
      }
    },
    setMin(val, step) {
      return Math.floor(val / step) * step;
    },
    setMax(val, step) {
      return Math.ceil(val / step) * step;
    },
    setPage(page) {
      this.page = page;
      this.searchSchool(null, page);
    },
    changeRank() {
      if (this.rank === "") {
        this.order = "name";
      } else {
        this.order = "rank";
      }
      this.searchSchool();
    },
    async searchSchool(val, page = 1) {
      // this.searchBox = false;
      // this.sortBox =  false;
      let rank = "";
      if (this.rank !== "") {
        rank = this.ranks[this.rank].id;
      }

      console.log(this.$route.path);
      this.$router.push({
        path: this.$route.path,
        query: {
          rank: rank,
          school: this.schoolName,
          page: page,
          applicant: this.enrollment,
          state: this.location,
          tuition: this.tuition,
          acceptance: this.rate,
          order: this.order,
          asc: this.sort
        }
      });
    },
    async fetchCustomLesson(counselingId) {
      const customLesson = await privateApi.getCustomLesson({
        class_id: counselingId
      });
      this.sessionClass = {
        ...customLesson.session_class
      };
      const {
        basic_info,
        student: { counseling_schools_type }
      } = await profileApi.getUserByName(
        customLesson.student_class_tickets[0].student.username
      );
      this.student = { ...basic_info, counseling_schools_type };
    },
    async collectSchool() {
      if (this.studentCounselingSchoolsType) {
        try {
          await collectSchoolApi.storeSchoolToCounseling({
            counselingId: 0,
            userId: this.studentUserId,
            schoolId: this.currentSchoolId,
            type: this.favoriteType,
            applicationType: this.applicationType,
            applicationPortal: this.$route.params.thirdAppType
          });
          this.fetchCollectedCounselingSchools();
          this.toggleCollectSchoolDialogVisible(false);
          this.toggleIfCountinueCollectSchoolDialogVisible(true);
          this.$message.success(this.$t("message.update_success"));
        } catch (e) {
          this.$message.error(this.$t("message.something_went_wrong"));
        }
      } else {
        this.isAskCounselingSchoolsTypeDialogVisible = true;
      }
    },
    async fetchCollectedCounselingSchools() {
      const {
        counseling_schools
      } = await collectSchoolApi.fetchCollectedCounselingSchools({
        counselingId: 0,
        userId: this.studentUserId,
        counselingSchoolsType: this.studentCounselingSchoolsType
      });
      this.collectedCounselingSchools = counseling_schools.map(
        counselingSchool => ({
          type: counselingSchool.type,
          relatedId: counselingSchool.id,
          schoolId: counselingSchool.school.id
        })
      );
    },
    async removeSchoolFromFavorite(schoolId) {
      const relatedCounselingSchool = this.getRelatedCounselingSchool(schoolId);
      try {
        await collectSchoolApi.removeSchoolFromCounseling(
          relatedCounselingSchool.relatedId
        );
        this.$message.success(this.$t("message.delete_success"));
        this.fetchCollectedCounselingSchools();
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async updateCounselingSchoolType(counselingSchoolsType) {
      if (this.isRoleAdmin() || this.isRoleTeacher()) {
        await this.$store.dispatch("profile/updateStudentProfileByAdmin", {
          id: this.studentUserId,
          newProfile: { counseling_schools_type: counselingSchoolsType }
        });
      } else {
        await this.$store.dispatch("profile/updateStudentProfile", {
          counseling_schools_type: counselingSchoolsType
        });
      }
      this.isAskCounselingSchoolsTypeDialogVisible = false;
      this.student = {
        ...this.student,
        counseling_schools_type: counselingSchoolsType
      };
      await this.$store.dispatch("user/getProfile");
      this.collectSchool();
    },
    collectSchoolIsLogin(state, schoolId) {
      if (this.isLogin) {
        this.toggleCollectSchoolDialogVisible(state, schoolId);
      } else {
        this.toggleSignUp = true;
      }
    },
    toggleCollectSchoolDialogVisible(state, schoolId) {
      this.favoriteType = "DEFAULT";
      this.applicationType = "RD";
      this.collectSchoolDialogVisible = state;
      this.currentSchoolId = state ? schoolId : null;
    },
    toggleIfCountinueCollectSchoolDialogVisible(state) {
      this.ifCountinueCollectSchoolDialogVisible = state;
    },
    getRelatedCounselingSchool(targetSchoolId) {
      return this.collectedCounselingSchools.find(
        ({ schoolId }) => schoolId === targetSchoolId
      );
    },
    showRank(ranks) {
      let showRank = "";
      if (this.ranks[this.rank]) {
        ranks.forEach(rank => {
          if (rank.pivot.rank_id === this.ranks[this.rank].id) {
            if (rank.site_name) {
              showRank = `<b>#${rank.rank}</b> in <span>${rank.rank_year} [${rank.site_name}] ${rank.rank_name["en"]}</span>`;
            } else {
              showRank = `<b>#${rank.rank}</b> in <span>${rank.rank_year} ${rank.rank_name["en"]}</span>`;
            }
          }
        });
      } else {
        if (ranks[0].site_name) {
          showRank = `<b>#${ranks[0].rank}</b> in <span>${ranks[0].rank_year} [${ranks[0].site_name}] ${ranks[0].rank_name["en"]}</span>`;
        } else {
          showRank = `<b>#${ranks[0].rank}</b> in <span>${ranks[0].rank_year} ${ranks[0].rank_name["en"]}</span>`;
        }
      }
      return showRank;
    },
    getCollectdSchoolCount(collectType) {
      return this.collectedCounselingSchools.filter(
        relatedSchool => relatedSchool.type === collectType
      ).length;
    },
    stick() {
      const header = document.querySelector("header");
      if (header) {
        const condition = header.offsetHeight;
        if (window.scrollY >= condition) {
          this.onTop = false;
        } else {
          this.onTop = true;
        }
      }
    }
  }
};
</script>

<style>
.el-select-dropdown {
  max-width: 95%;
}
</style>
<style scoped>
.schools {
  min-height: 1240px;
  color: #5f5f5f;
}
.schools,
::v-deep .el-pagination,
::v-deep .el-pagination button:disabled,
::v-deep .el-pager li,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pagination .btn-next {
  background-color: #f3f3f1;
}
::v-deep .container {
  padding-top: 0;
}
.header {
  height: 170px;
  background-image: url(https://ivy-way.s3.ap-northeast-1.amazonaws.com/images/college-search.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.search-header {
  padding: 20px;
  margin-bottom: 40px;
  background: #fff;
  box-shadow: 0 1px 4px rgb(0 0 0 / 10%), 0 0 2px rgb(0 0 0 / 10%);
}
.search-header-title {
  color: #464646;
  font-size: 32px;
  line-height: 34px;
  text-shadow: none;
  margin-bottom: 10px;
}
.search-description {
  color: #5f5f5f;
  font-size: 16px;
  line-height: 20px;
}
.row {
  margin: 0;
  padding: 0;
}
.container >>> .el-dialog__body {
  padding: 10px 20px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}
.col-md-4.school {
  padding: 0;
}
.star-count {
  float: right;
  color: rgba(0, 0, 0, 0.5);
}
.school {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #5f5f5f;
}
.school:hover {
  background-color: #fbf5f2;
}
.school-detail {
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.school-img {
  width: 200px;
  /* height: 200px; */
  overflow: hidden;
  padding-right: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.school-img a {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
  /* height: 100%; */
}
.mobile .school-img {
  width: 120px;
  height: 120px;
  padding: 0;
}
.mobile .school-img a {
  /* width: 120px;
  height: 120px; */
}
.school-img a img {
  display: block;
  height: 100%;
}
.school-action {
  padding: 15px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.overview {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 10px;
  display: flex;
  text-decoration: none;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.overview .subtitle small {
  font-size: 14px;
}
.overview-content {
  /* max-height: 100px;
  overflow: hidden; */
  /* line-height: 1.2; */
}
.sort {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px;
  width: 150px;
}
.sort .sort-title {
  font-size: 16px;
  font-weight: 700;
}
.sort-detail-sm .sort-title {
  font-size: 14px;
}
.sort .sort-val {
  font-size: 16px;
}
.sort-detail-sm .sort-value {
  font-size: 14px;
}
/* .subtitle {
} */
.subtitle .school-name {
  font-size: 22px;
  transition: 1.5s;
  font-weight: 600;
  /* height: 30px; */
  /* overflow: hidden; */
}
.school-detail-sm .subtitle .school-name {
  font-size: 18px;
  transition: 1.5s;
  font-weight: 600;
  /* height: 30px; */
  /* overflow: hidden; */
}
.school-detail-sm {
  line-height: 1.2;
}
.filter {
  padding: 0 10px;
}
::v-deep .el-card.is-always-shadow {
  box-shadow: 0 1px 4px rgb(0 0 0 / 10%), 0 0 2px rgb(0 0 0 / 10%);
}
.filter >>> .el-card__body {
  padding: 0;
}
.school:hover {
}
.school >>> .el-card__body {
  padding: 0;
}
.filter >>> .el-collapse-item__header {
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.21;
  letter-spacing: 1.62px;
  text-transform: uppercase;
  height: auto;
  min-height: 48px;
}
.mobile .filter >>> .el-collapse-item__header {
  padding-right: 20px;
}
.filter >>> .el-collapse {
  border: none;
}
.search {
  position: fixed;
  top: 432px;
  left: 0;
  right: 0;
  overflow: auto;
  transition-duration: 1s;
}
.search-scorll {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  overflow: auto;
  transition-duration: 0.5s;
}
.filter small {
  display: block;
  margin-top: 5px;
  color: #42a16a;
  text-transform: none;
}
.icon {
  width: 16px;
  /* display: inline-block; */
  margin: 0 0 auto;
}
.item-body {
  padding: 0 10px;
}
.filter >>> .el-collapse-item__content {
  padding: 0 20px 30px 20px;
}
.mobile .filter >>> .el-collapse-item__content {
  padding-right: 40px;
}
#banner {
  display: -ms-flexbox;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  padding: 8em 0 6em 0;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  height: 400px;
  position: relative;
  background: #000;
  /* background-image: url(../../assets/mainbg.webp); */
  background-size: cover;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  color: #fff;
  filter: contrast(90%);
}
#banner:before {
  -moz-transition: opacity 3s ease;
  -webkit-transition: opacity 3s ease;
  -ms-transition: opacity 3s ease;
  transition: opacity 3s ease;
  -moz-transition-delay: 0.25s;
  -webkit-transition-delay: 0.25s;
  -ms-transition-delay: 0.25s;
  transition-delay: 0.25s;
  content: "";
  display: block;
  background-color: #000;
  height: 100%;
  left: 0;
  opacity: 0.65;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
#banner .inner {
  -moz-transform: none;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  -moz-transition: opacity 1s ease, transform 1s ease;
  -webkit-transition: opacity 1s ease, transform 1s ease;
  -ms-transition: opacity 1s ease, transform 1s ease;
  transition: opacity 1s ease, transform 1s ease;
  position: relative;
  opacity: 1;
  z-index: 3;
  padding: 0 2em;
}

#banner h1 {
  /* font-size: 4em; */
  line-height: 1em;
  margin: 0 0 0.5em 0;
  padding: 0;
  color: #fff;
  line-height: 100%;
}

#banner p {
  /* font-size: 1.5em; */
  margin-bottom: 1.75em;
}

#banner a {
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 736px) {
  #banner {
    background-attachment: scroll;
  }
  #banner .inner {
    padding: 0 1em;
  }
  #banner h1 {
    font-size: 2.25em;
  }

  #banner p {
    font-size: 1.25em;
  }
}
</style>

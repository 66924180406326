export default class College {
  static getSchoolApplicationTypes(school) {
    const removeApplicationTypeNumber = applicationTypes => {
      let newApplicationTypes = [...applicationTypes];
      const checkApplicationTypes = ["EA", "ED"];
      checkApplicationTypes.forEach(checkApplicationType => {
        if (
          applicationTypes.includes(`${checkApplicationType}1`) &&
          !applicationTypes.includes(`${checkApplicationType}2`)
        ) {
          const targetIndex = newApplicationTypes.findIndex(
            applicationType => applicationType === `${checkApplicationType}1`
          );
          newApplicationTypes.splice(targetIndex, 1, checkApplicationType);
        }
      });

      return newApplicationTypes;
    };

    const applicationTypeKeys = Object.keys(school).filter(key =>
      key.includes("has_")
    );

    const applicationTypes = applicationTypeKeys
      .filter(key => school[key])
      .map(key => key.replace("has_", "").toLocaleUpperCase());

    return removeApplicationTypeNumber([...applicationTypes, "RD"]);
  }
}

import ApiService from "@/common/api.service";

export default {
  fetchCollectedCounselingSchools({
    counselingId,
    userId,
    type,
    counselingSchoolsType
  }) {
    return ApiService.query("counseling-schools", {
      session_class_id: counselingId,
      student_user_id: userId,
      counseling_schools_type: counselingSchoolsType,
      type
    });
  },
  fetchCollectedSchool(id) {
    return ApiService.get(`counseling-school/${id}`);
  },
  storeSchoolToCounseling({
    counselingId,
    userId,
    schoolId,
    type,
    applicationPortal,
    applicationType
  }) {
    return ApiService.post("counseling-school", {
      is_applying: 1,
      session_class_id: counselingId,
      student_user_id: userId,
      school_id: schoolId,
      application_portal: applicationPortal,
      application_type: applicationType,
      type
    });
  },
  batchStoreSchoolToCounseling(collectSchools) {
    return ApiService.post("counseling-school/batch", collectSchools);
  },
  updateCollectedSchool(collectedSchool) {
    return ApiService.patch("counseling-school", collectedSchool);
  },
  removeSchoolFromCounseling(relatedCounselingSchoolId) {
    return ApiService.delete(`counseling-school/${relatedCounselingSchoolId}`);
  },
  batchRemoveSchoolFromCounseling(studentUserId, collectSchools) {
    return ApiService.delete("counseling-school/batch", {
      session_class_id: 0,
      student_user_id: studentUserId,
      school_ids: collectSchools
    });
  }
};

import ApiService from "@/common/api.service";

export default {
  addCustomPrivateLesson(data) {
    return ApiService.post("add-custom-lesson", { ...data, type: 2 });
  },
  updateCustomPrivateLesson(data) {
    return ApiService.post("update-custom-lesson", { ...data, type: 2 });
  },
  getCustomLesson(data) {
    return ApiService.query("get-custom-lesson", data);
  },
  fetchLessonRequestsByTeacher({ has_replied }) {
    return ApiService.query("class-teacher-invitations/mine", { has_replied });
  },
  changeToCounseling(sessionClassId) {
    return ApiService.patch(
      `change-private-lesson-to-counseling/${sessionClassId}`
    );
  }
};
